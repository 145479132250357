import {
  KeyboardEvent,
  ChangeEvent,
  FocusEventHandler,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import isEmail from 'validator/lib/isEmail';
import { useTranslation } from 'react-i18next';
import { LabeledInputWrapper } from './LabeledInputWrapper';
import { classNames } from 'utilities/classNames';

interface Props {
  label: string;
  formName?: string;
  required?: boolean;
  autoComplete?: string;
  value?: string | null;
  type: InputType;
  small?: boolean;
  tiny?: boolean;
  helpText?: string | null;
  min?: number | string;
  max?: number | string;
  step?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
  placeholder?: string | null;
  autoFocus?: boolean;
  disabled?: boolean;
  customInputValidation?: (e: string) => string | null | undefined;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export type InputType =
  | 'text'
  | 'email'
  | 'tel'
  | 'number'
  | 'positiveNumber'
  | 'date'
  | 'month'
  | 'time';

export const dateInputFormat = 'YYYY-MM-DD';
export const timeInputFormat = 'HH:mm';
export const monthInputFormat = 'YYYY-MM';

/**
 * a basic text input with the label on the left
 * from the tailwind form layout https://tailwindui.com/components/application-ui/forms/form-layouts#component-cc5bee94bc88387c2511b0e5c3dfffef
 */
export const BasicTextInput: FunctionComponent<Props> = ({
  label,
  formName,
  required,
  autoComplete,
  value,
  small,
  tiny,
  type,
  helpText,
  min,
  max,
  step,
  onChange,
  onBlur,
  className,
  placeholder,
  autoFocus,
  disabled,
  customInputValidation,
  onKeyDown,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'formFields.basicTextInput',
  });

  function validateEmail(inputValue: string) {
    if (inputValue && !isEmail(inputValue)) {
      ref.current?.setCustomValidity(t('emailInvalid'));
    } else {
      ref.current?.setCustomValidity('');
    }
  }

  useEffect(() => {
    if (autoFocus) ref.current?.focus();
  }, [autoFocus]);

  async function validatePhoneNumber(inputValue: string) {
    const isValidPhoneNumber = (await import('libphonenumber-js'))
      .isValidPhoneNumber;
    if (inputValue && !isValidPhoneNumber(inputValue, 'CH')) {
      ref.current?.setCustomValidity(t('phoneInvalid'));
    } else {
      ref.current?.setCustomValidity('');
    }
  }

  function validateMonthInput(inputValue: string) {
    const year = Number(inputValue.substring(0, 4));
    const month = Number(inputValue.substring(5, 7));
    if (
      isNaN(year) ||
      (inputValue && inputValue.trim().length !== 7) ||
      !inputValue.includes('-') ||
      !(month <= 12) ||
      !(month >= 1)
    ) {
      ref.current?.setCustomValidity(t('monthFormatInvalid'));
      ref.current?.reportValidity();
    } else {
      ref.current?.setCustomValidity('');
    }
  }

  function validateDateInput(inputValue: string) {
    if (inputValue !== '' && Number(inputValue.substring(0, 4)) < 1000) {
      // we don't report validity here because that messes with the date input's internal logic so that users cannot type anything into the input
      // validity will be reported on blur
      ref.current?.setCustomValidity(t('yearMustBeAtLeast1000'));
      return false;
    } else if (
      (required || inputValue !== '') &&
      min != undefined &&
      inputValue < min
    ) {
      ref.current?.setCustomValidity(t('valueMustBeAtLeast', { min }));
      return false;
    } else if (
      (required || inputValue !== '') &&
      max != undefined &&
      inputValue > max
    ) {
      ref.current?.setCustomValidity(t('valueMustBeAtMost', { max }));
      return false;
    } else {
      ref.current?.setCustomValidity('');
    }
    return true;
  }

  function customValidation(inputValue: string) {
    const validationMessage = customInputValidation?.(inputValue);
    if ((required || inputValue) && validationMessage) {
      ref.current?.setCustomValidity(validationMessage);
      ref.current?.reportValidity();
    } else {
      ref.current?.setCustomValidity('');
    }
  }

  // input value shouldn't be empty (only contains whitespace)
  function validateNotEmpty(inputValue: string) {
    if (inputValue && inputValue.trim() == '' && required) {
      ref.current?.setCustomValidity(t('emptyValueInvalid'));
      ref.current?.reportValidity();
    } else {
      ref.current?.setCustomValidity('');
    }
  }

  function validateInput(e: ChangeEvent<HTMLInputElement>) {
    validateNotEmpty(e.target.value);
    setInputValue(e.target.value);

    if (customInputValidation) customValidation(e.target.value);

    if (type === 'tel') void validatePhoneNumber(e.target.value);
    else if (type === 'email') validateEmail(e.target.value);
    // don't trigger onChange for dates with a year with less than 4 digits because they are intermediate values and might cause weird behavior
    else if (type === 'month') validateMonthInput(e.target.value);
    else if (type === 'date') {
      if (!validateDateInput(e.target.value)) return;
    }

    onChange?.(e);
  }

  function validateKeypress(e: KeyboardEvent<HTMLInputElement>) {
    onKeyDown?.(e);
    // positiveNumber inputs should not allow any characters to be input
    if (type === 'positiveNumber' && ['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
    if (type === 'number') {
      if (['e', 'E', '+'].includes(e.key)) e.preventDefault();
      if (e.key === '-' && ref.current?.value?.includes('-')) {
        e.preventDefault();
      }
    }
  }

  function trimInput() {
    if (!ref.current) return;
    ref.current.value = ref.current.value?.trim();
  }

  const ref = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(value?.trim() ?? '');

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  return (
    <LabeledInputWrapper
      helpText={helpText}
      label={tiny ? '' : label}
      required={required}
      className={className}
      small={small}
    >
      <input
        type={type === 'positiveNumber' ? 'number' : type}
        name={formName}
        onBlur={(e) => {
          trimInput();
          if (type === 'date' && !ref.current?.checkValidity()) {
            ref.current?.reportValidity();
          }
          onBlur?.(e);
        }}
        id={formName}
        min={min}
        max={max}
        ref={ref}
        onChange={validateInput}
        required={required}
        autoComplete={autoComplete || undefined}
        value={inputValue}
        step={step}
        autoFocus={autoFocus}
        placeholder={
          tiny ? label + (required ? ' *' : '') : placeholder ?? undefined
        }
        className={classNames(
          'block w-full focus:ring-accent-500 focus:border-accent-500 text-sm border-gray-300 rounded-lg',
          !small && 'max-w-lg sm:max-w-xs',
          disabled && 'cursor-not-allowed bg-gray-50 text-gray-500',
        )}
        onKeyDown={validateKeypress}
        disabled={disabled}
      />
    </LabeledInputWrapper>
  );
};

BasicTextInput.defaultProps = {
  required: false,
  value: '',
  autoComplete: '',
  helpText: null,
};
