import capitalize from 'lodash/capitalize';

export function snakeCaseToReadable(text: string): string {
  if (!text) return text;
  const words = text.split(' ');
  return words
    .map((w) => {
      if (!w.includes('_')) return w;
      return w
        .split('_')
        .map((p) => capitalize(p))
        .join(' ');
    })
    .join(' ');
}
