import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationPopup } from './ConfirmationPopup';

interface Props {
  message?: string | null;
  open: boolean;
  onClose: () => void;
  onOk: () => void | Promise<void>;
}

// modal based on the tailwind component https://tailwindui.com/components/application-ui/overlays/modals#component-f39bfe26a95719b1611d27f969be9a24
export const DeleteConfirmationPopup: FunctionComponent<Props> = ({
  open,
  message,
  onClose,
  onOk,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'deleteConfirmationPopup',
  });

  return (
    <ConfirmationPopup
      open={open}
      title={t('title')}
      icon={<></>}
      okText={t('delete')}
      accentColor="red"
      message={message}
      onOk={onOk}
      onClose={onClose}
    />
  );
};
