import React, { FunctionComponent, useState } from 'react';
import { ButtonThemeColor } from '../Common/Buttons/Button';
import { Popup } from './Popup';

interface Props {
  message?: string | null;
  open: boolean;
  onClose: () => void;
  onOk: () => void | Promise<void>;
  okText?: string | null;
  title?: string | null;
  accentColor?: ButtonThemeColor;
  icon?: JSX.Element;
}

// modal based on the tailwind component https://tailwindui.com/components/application-ui/overlays/modals#component-f39bfe26a95719b1611d27f969be9a24
export const ConfirmationPopup: FunctionComponent<Props> = ({
  open,
  message,
  onClose,
  onOk,
  okText,
  title,
  accentColor,
  icon,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Popup
      open={open}
      title={title}
      icon={icon}
      okText={okText}
      accentColor={accentColor}
      message={message}
      onOk={async () => {
        setIsLoading(true);
        await onOk();
        setIsLoading(false);
        onClose?.();
      }}
      isOkActionLoading={isLoading}
      onClose={onClose}
    />
  );
};
