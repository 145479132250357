import { UserType, useAppContext } from '../context/appContext';
import { Permission, Scope } from '../typings/roleConfig';
import useContract from './useContract';

export default function useUserPermission(
  permission: Permission | null,
  scope: Scope,
): boolean {
  if (permission === null) return true;
  const { user, userType } = useAppContext(UserType.BOTH);
  const { contract } = useContract();
  if (userType === UserType.STUDENT) return false;
  if (!user.role) return false;
  if (!contract && scope > Scope.READ) return false;
  if (!user.role) return false;
  if (user.role.isAdmin) return true;

  const userPermission = user.role.permissions[permission];
  if (userPermission === undefined) return false;
  return userPermission >= scope;
}
