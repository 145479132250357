import { captureException } from '@sentry/nextjs';
import { TokenGeneratorSingleton } from '../auth/TokenGeneratorSingleton';
import { camelCaseToReadable } from '../utilities/caseConversion/camelCaseToReadable';
import { snakeCaseToReadable } from '../utilities/caseConversion/snakeCaseToReadable';
import { CustomError } from './customError';

type Method = 'POST' | 'PATCH' | 'DELETE';

export default async function authenticatedPost(
  url: string,
  body: any,
  method?: Method,
  options?: {
    formData: boolean;
  },
): Promise<Response> {
  const accessToken =
    await TokenGeneratorSingleton.getInstance().tokenGenerator();
  const res = await fetch(url, {
    method: method ?? 'POST',
    headers: options?.formData
      ? {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        }
      : {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
    body: options?.formData ? body : JSON.stringify(body),
  });
  if (!res.ok) {
    const json = await res.json();
    let message = json.response?.message;
    if (Array.isArray(message)) {
      message = message.join(', ');
    }
    message = camelCaseToReadable(snakeCaseToReadable(message));
    const error = new CustomError(
      res.status,
      res.statusText,
      message,
      {
        url,
        body,
        method: method ?? 'POST',
        response: json.response,
      },
      json.response?.translationKey,
    );
    captureException(error, {
      extra: { requestUrl: url, method, requestStatus: res.status },
    });
    throw error;
  }
  return res;
}
