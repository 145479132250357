import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React, { FunctionComponent, SVGProps } from 'react';
import { Color } from '../../typings/backend-types';
import { classNames } from 'utilities/classNames';

interface Props {
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  className?: string;
  color?: Color;
  large?: boolean;
}

/**
 * a small infotext with the specified icon or an infoCircleIcon
 */
export const InfoText: FunctionComponent<Props> = ({
  icon = InformationCircleIcon,
  className,
  children,
  color,
  large,
}) => {
  const Icon = icon;
  if (!children) return null;
  return (
    <div
      className={classNames(
        'font-normal',
        large ? 'text-base' : 'text-xs',
        className,
      )}
    >
      <div className="flex">
        <Icon
          className={classNames(
            large ? 'w-5 h-5 mt-0.5' : 'w-4 h-4',
            'shrink-0 align-text-bottom mr-1',
            `text-${color}-500`,
          )}
        />
        <span className={`text-${color}-500`}>{children}</span>
      </div>
    </div>
  );
};

InfoText.defaultProps = {
  color: Color.Slate,
};
