import React, { FunctionComponent } from 'react';
import { InfoText } from '../../Common/InfoText';
import { classNames } from 'utilities/classNames';

interface Props {
  label?: string | null;
  required?: boolean;
  small?: boolean;
  className?: string;
  helpText?: string;
}

export const LabeledInputWrapper: FunctionComponent<Props> = ({
  label,
  required,
  small,
  className,
  children,
  helpText,
}) => {
  return (
    <div
      className={classNames(
        !small &&
          'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5',
        className,
      )}
    >
      <label
        className={classNames(
          'block text-sm font-medium text-gray-700',
          !small && 'sm:mt-px sm:pt-2',
        )}
      >
        {label} {required && label && '*'}
      </label>
      <div className={classNames('mt-1', !small && 'sm:mt-0 sm:col-span-2')}>
        {children}
        {helpText && <InfoText className="mt-2">{helpText}</InfoText>}
      </div>
    </div>
  );
};
