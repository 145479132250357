import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { FormEvent, ReactNode, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning';
import { Permission, Scope } from '../../typings/roleConfig';
import useUserPermission from '../../hooks/useUserPermission';
import { useAppContext } from '../../context/appContext';
import { Button } from '@components/Common/Buttons/Button';
import { DeleteConfirmationPopup } from '@components/InfoScreens/DeleteConfirmationPopup';

interface Props<T> {
  onSubmit: (e: FormEvent<T>) => Promise<void> | void;
  onCancel?: () => void;
  onSubmitError?: (e: unknown) => void;
  onBlur?: (e: React.FocusEvent<HTMLFormElement, Element>) => void;
  additionalButtons?: React.ReactNode;
  children: ReactNode;
  saveDisabled?: boolean;
  hideButtons?: boolean;
  onDelete?: never;
}

interface PropsWithDelete<T> extends Omit<Props<T>, 'onDelete'> {
  onDelete: () => void;
  deleteConfirmationText?: string | null;
  deleteDisabled?: boolean;
  deletePermission: Permission | { isAdmin: true } | null;
}

/**
 * A basic form wrapper with onSubmit, onDelete and onCancel.
 * Before the delete action is performed, a popup opens to let the user confirm the deletion.
 * based on https://tailwindui.com/components/application-ui/forms/form-layouts#component-cc5bee94bc88387c2511b0e5c3dfffef
 */
export function Form<T = HTMLFormElement>(
  props: Props<T> | PropsWithDelete<T>,
): JSX.Element {
  const {
    onSubmit,
    onSubmitError,
    onCancel,
    onBlur,
    additionalButtons,
    children,
    saveDisabled,
    hideButtons,
  } = props;

  const [deleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'form' });
  const hasUnsavedChanges = useRef(false);

  useUnsavedChangesWarning(hasUnsavedChanges);

  const deleteConfirmationText =
    ('deleteConfirmationText' in props && props.deleteConfirmationText) ||
    t('deleteConfirmationText');

  const onDelete: (() => void) | undefined =
    'onDelete' in props ? props.onDelete : undefined;

  const { user } = useAppContext();
  const hasDeletePermission = useUserPermission(
    'deletePermission' in props && typeof props.deletePermission === 'string'
      ? props.deletePermission
      : null,
    Scope.READ_WRITE_DELETE,
  );

  const canDelete = useMemo(() => {
    if (!('deletePermission' in props)) return true;
    if (
      props.deletePermission &&
      typeof props.deletePermission === 'object' &&
      props.deletePermission.isAdmin
    ) {
      return user.role?.isAdmin;
    }
    return hasDeletePermission;
  }, [hasDeletePermission, user, props]);

  return (
    <>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={async (e: FormEvent<T & HTMLFormElement>) => {
          e.preventDefault();
          hasUnsavedChanges.current = false;
          if (isSubmitLoading) return;
          setIsSubmitLoading(true);
          try {
            await onSubmit(e);
          } catch (err) {
            onSubmitError?.(err);
          }
          setIsSubmitLoading(false);
        }}
        onChange={() => (hasUnsavedChanges.current = true)}
        onBlur={onBlur}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">{children}</div>
        </div>

        <div className="pt-5">
          <div className="flex place-content-between">
            {!hideButtons && (
              <>
                <div>{additionalButtons}</div>
                <div className="flex justify-end">
                  {!!onCancel && (
                    <Button
                      onClick={() => {
                        hasUnsavedChanges.current = false;
                        onCancel();
                      }}
                      className="mr-3"
                    >
                      {t('cancel')}
                    </Button>
                  )}
                  {!!onDelete && canDelete && (
                    <Button
                      onClick={() => {
                        setDeleteConfirmationPopupOpen(true);
                      }}
                      color="red"
                      className="mr-3"
                      disabled={
                        'deleteDisabled' in props && props.deleteDisabled
                      }
                      icon={TrashIcon}
                    >
                      {t('delete')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    color="primary"
                    icon={CheckIcon}
                    disabled={saveDisabled}
                    loading={isSubmitLoading}
                  >
                    {t('save')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
      {!!onDelete && canDelete && (
        <DeleteConfirmationPopup
          open={deleteConfirmationPopupOpen}
          message={deleteConfirmationText}
          onOk={() => {
            hasUnsavedChanges.current = false;
            onDelete();
          }}
          onClose={() => setDeleteConfirmationPopupOpen(false)}
        />
      )}
    </>
  );
}
