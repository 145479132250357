import Router from 'next/router';
import { RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useUnsavedChangesWarning = (
  hasUnsavedChanges?: RefObject<boolean>,
  confirmCallback?: () => boolean,
) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common.unsavedChangesWarning',
  });

  useEffect(() => {
    const routeChangeStart = () => {
      // Check if the event should be ignored
      if (hasUnsavedChanges?.current ?? true) {
        const ok = confirmCallback?.() ?? confirm(t('message'));
        if (!ok) {
          Router.events.emit('routeChangeError');
          throw 'Abort route change. Please ignore this error.';
        }
      }
    };

    Router.events.on('routeChangeStart', routeChangeStart);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
    };
  }, []);
};
