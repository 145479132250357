import capitalize from 'lodash/capitalize';

export function camelCaseToReadable(text: string): string {
  if (!text) return text;
  const words = text.split(' ');
  return words
    .map((w) => {
      if (!/.*[A-Z].*/.test(w)) return w;
      return w
        .split(/(?=[A-Z])/g)
        .map((p) => capitalize(p))
        .join(' ');
    })
    .join(' ');
}
