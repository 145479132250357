import React, { FunctionComponent } from 'react';

interface Props {
  title: string;
  hidden?: boolean;
  help?: JSX.Element;
}

export const FormSection: FunctionComponent<Props> = ({
  title,
  children,
  hidden,
  help,
}) => {
  return (
    <>
      {!hidden && (
        <div className="pt-4 space-y-6 sm:pt-6 sm:space-y-5 pb-3">
          <h3 className="text-lg leading-6 font-semibold text-gray-900">
            {title}
            {help}
          </h3>
        </div>
      )}
      <div className="space-y-6 sm:space-y-5">{children}</div>
    </>
  );
};
